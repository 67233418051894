<template>
  <div>
    <div v-if="form.submitStatus === 'OK'" class="form__success">
      <h1><i class="fal fa-check-circle" /></h1>
      <h2>Journey Updated</h2>
    </div>
    <div v-if="showLevel">
      <LevelForm
        :mode="levelMode"
        :journey-id="journeyId"
        :level-id="currentLevel"
        @onSubmit="onSubmit()"
      />
    </div>
    <!-- <div v-if="showContent">
      <LevelForm
        :mode="contentMode"
        :journey-id="journeyId"
        :level-id="currentLevel"
        @onSubmit="onSubmit()"
      />
    </div> -->
    <form
      v-if="form.submitStatus !== 'OK' && !showLevel"
      class="form"
      @submit.prevent="submit"
    >
      <Field label="Name" type="black">
        <TextInput
          v-model.trim="$v.form.name.$model"
          placeholder="Journey name"
        />
      </Field>

      <Field label="Subtitle" type="black">
        <TextInput
          v-model.trim="$v.form.subtitle.$model"
          placeholder="Journey subtitle"
        />
      </Field>

      <FieldGroup>
        <Field label="Card Image" type="black">
          <FileInput v-model.trim="$v.form.imagePath.$model" />
        </Field>

        <Field label="Header Image" type="black">
          <FileInput v-model.trim="$v.form.headerImagePath.$model" />
        </Field>
      </FieldGroup>

      <Field label="Description" type="black">
        <TextAreaInput
          v-model.trim="$v.form.description.$model"
          placeholder="Description"
        />
      </Field>

      <Field label="Savings" type="black">
        <TextInput v-model.trim="$v.form.savings.$model" placeholder="100" />
      </Field>

      <Field label="Tax Relief" type="black">
        <TextInput v-model.trim="$v.form.taxRelief.$model" placeholder="60" />
      </Field>

      <FlexContainer
        v-if="mode === 'edit'"
        justify-content="flex-start"
        :wrap="true"
      >
        <FlexItem v-for="level in levels" :key="level.id" padding="small">
          <Card class="level">
            <div @click="showEditLevelForm(level.id)">
              <ImageItem :src="level.cardImagePath" class="level__image" />
              <div class="level__points">
                <Button type="primary" size="small">
                  {{ level.pointsAvailable }}pts
                </Button>
              </div>
              <FlexItem
                padding-horizontal="normal"
                class="level__content"
                padding="small"
              >
                <div>
                  <p class="level__subheading">Level {{ level.levelLabel }}</p>
                  <h6 class="level__heading">
                    {{ level.name }}
                  </h6>
                </div>
              </FlexItem>
            </div>
          </Card>
        </FlexItem>
        <FlexItem>
          <Card class="level">
            <div @click="showAddLevelForm()">
              <FlexItem padding-horizontal="normal" class="level__content">
                <Button type="success">
                  Add Level
                </Button>
              </FlexItem>
            </div>
          </Card>
        </FlexItem>
      </FlexContainer>
      <Field>
        <ButtonSubmit type="secondary">
          Submit
        </ButtonSubmit>
      </Field>
    </form>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import { mapState, mapActions, mapGetters } from 'vuex'
import LevelForm from '@/components/admin/LevelForm'

export default {
  components: {
    LevelForm
  },
  props: {
    journeyId: {
      type: String,
      default: null
    },
    levelId: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      id: null,
      showLevel: false,
      levelMode: 'add',
      currentLevel: null,
      form: {
        journeyId: null,
        name: '',
        imagePath: '',
        headerImagePath: '',
        subtitle: '',
        description: '',
        savings: null,
        taxRelief: null,
        submitStatus: null
      }
    }
  },
  computed: {
    ...mapState({
      journeys: state => state.journeys.journeys,
      levels: state => state.levels.levels
    }),
    ...mapGetters({
      journey: 'journey/getJourney'
    }),
    publisherOptions() {
      return Object.keys(this.publishers).map(id => {
        return {
          text: this.publishers[id].name,
          value: id
        }
      })
    },
    productOptions() {
      return Object.keys(this.products).map(id => {
        const product = this.products[id]
        return {
          text: `
            ${product.shorturl} -
            ${product.brand} ${product.name} -
            ${product.colour}`,
          value: id
        }
      })
    }
  },
  watch: {
    journeyId: {
      immediate: true,
      handler(value) {
        console.log('watchjId', value, this)
        this.id = value

        if (this.id) {
          this.$store.dispatch('journey/openDBChannel', {
            pathVariables: {
              journeyId: this.id
            }
          })

          this.$store.dispatch('levels/openDBChannel', {
            pathVariables: {
              journeyId: this.journeyId
            }
          })
        } else {
          this.$store.dispatch('journey/closeDBChannel', {
            clearModule: true
          })

          this.$store.dispatch('levels/closeDBChannel', {
            clearModule: true
          })
        }
      }
    },
    journey: {
      immediate: true,
      handler() {
        if (this.id) {
          // this.journey = this.getJourney(this.id)
          console.log('journey!', this.journey)
          this.form.id = this.journey.id
          this.form.name = this.journey.name
          this.form.imagePath = this.journey.imagePath
          this.form.headerImagePath = this.journey.headerImagePath
          this.form.subtitle = this.journey.subtitle
          this.form.description = this.journey.description
          this.form.savings = this.journey.savings
          this.form.taxRelief = this.journey.taxRelief
        }
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      imagePath: {},
      headerImagePath: {},
      subtitle: {},
      description: {},
      savings: {
        numeric
      },
      taxRelief: {
        numeric
      }
    }
  },
  methods: {
    ...mapActions({
      addJourney: 'journeys/addJourney',
      editJourney: 'journey/editJourney'
    }),
    showAddLevelForm() {
      this.levelMode = 'add'
      this.currentLevel = null
      this.showLevel = true
    },
    showEditLevelForm(id) {
      this.levelMode = 'edit'
      this.currentLevel = id
      this.showLevel = true
    },
    onSubmit() {
      setTimeout(() => {
        this.showLevel = false
      }, 2000)
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.form.submitStatus = 'ERROR'
      } else {
        this.form.submitStatus = 'OK'
        this.$emit('onSubmit')
        console.log('submit mode:', this.mode)
        switch (this.mode) {
          case 'edit':
            this.editJourney(this.form)
            break
          case 'duplicate':
            delete this.form.id
            this.addJourney(this.form)
            break
          default:
            delete this.form.id
            this.addJourney(this.form)
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form__success {
  padding: $padding 0;
  text-align: center;

  h1 {
    color: $color-secondary;
    font-size: 4.8rem;
  }

  h2 {
    color: $color-primary;
    font-size: 2.4rem;
    font-weight: 400;
  }
}

.level {
  position: relative;
  width: 200px;
  height: 150px;
}

.level__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 150px;
  z-index: 0;
  background: black;
}

.level__content {
  position: relative;
  z-index: 1;
  color: $color-white;
}

.level__points {
  position: absolute;
  top: 15px;
  right: 5px;
}

.level__subheading {
  font-size: 14px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 0;
}

.level__heading {
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
}
</style>
